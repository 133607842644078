import {React, useEffect, useMemo, useState} from "react"
import {MaterialReactTable, useMaterialReactTable} from "material-react-table"
import {citiesList, data, traNoList, usStateList} from "./makeData"
import {FaSync} from "react-icons/fa"
//Date Picker Imports - these should just be in your Context Provider
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider"
import {useDispatch, useSelector} from "react-redux"
import {
  getReportsList,
  getSLReport,
  sendToSheet,
  track
} from "../actions/SLReportActions"
import Loader from "../components/Loader"
import {Card, Col, Row} from "react-bootstrap"

import FileDownloadIcon from "@mui/icons-material/FileDownload"
import RadarIcon from "@mui/icons-material/Radar"
import GridOnIcon from "@mui/icons-material/GridOn"

import {mkConfig, generateCsv, download} from "export-to-csv" //or use your library of choice here

import "./Dashboard.scss"
import {Box, Button, Icon, Link, Typography} from "@mui/material"
import {SET_COLUMNS} from "../constants/columnConstants"

import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/successModal"

import {
  BillsColumns,
  DailyAnalyzerColumns,
  LabelsColumns,
  UsersColumns
} from "./columns"

import axios from "axios"
import pako from "pako"

const UTCToLocal = (utcTimeString) => {
  // Create a Date object from the UTC time string
  const utcDate = new Date(utcTimeString)

  // Convert UTC time to local time
  const localTime = new Date(
    utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
  )

  // Extract local time components
  const localYear = localTime.getFullYear()
  const localMonth = localTime.getMonth() + 1 // Months are 0-indexed, so add 1
  const localDay = localTime.getDate()
  const localHours = localTime.getHours()
  const localMinutes = localTime.getMinutes()
  const localSeconds = localTime.getSeconds()
  return `Local Time: ${localYear}-${localMonth}-${localDay} ${localHours}:${localMinutes}:${localSeconds}`
}

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true
})

const Example = ({records, selectedReport, SLReportsLists}) => {
  const dispatch = useDispatch()

  const column = useSelector((state) => state.column)
  const {definedColumns} = column

  const sendToGoogleSheet = useSelector((state) => state.sendToGoogleSheet)
  const {loading: sendToSheetLoading} = sendToGoogleSheet

  const labelToTrack = useSelector((state) => state.labelToTrack)
  const {loading: trackLoading} = labelToTrack

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original)
    const csv = generateCsv(csvConfig)(rowData)
    download(csvConfig)(csv)
  }

  const handleExportToSheet = (rows) => {
    const payload = rows.map((row) => row.original)
    dispatch(sendToSheet(payload))
    // const csv = generateCsv(csvConfig)(rowData)
    // download(csvConfig)(csv)
  }

  const handleTrack = (allRows, selectedRows) => {
    // Here you implement your update logic based on the selectedRows
    const rowData = allRows.map((row) => row.original)
    // Example: updating a property in the data
    let payload = []
    const updatedData = selectedRows.map((selectedRow) => {
      payload.push({
        trackingNo: selectedRow.original["Tracking NO"],
        index: Number(selectedRow.id)
      })
    })
    dispatch(track(payload, selectedReport, SLReportsLists, rowData))
  }

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data)
    download(csvConfig)(csv)
  }

  // const columns = useMemo(() => definedColumns || [], [])

  const keywords = [
    "Unpaid postage",
    "counterfeit",
    "will not be delivered",
    "seized",
    "intercepted",
    "intercept",
    "law",
    "Improper",
    "refused"
  ]

  const data = records
  const table = useMaterialReactTable({
    columns: definedColumns,
    data,
    // enableEditing: true,
    // columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    enableRowSelection: true,
    isMultiSortEvent: () => true,
    initialState: {
      columnVisibility: {
        status: false,
        FROM_last_name: false,

        FROM_address2: false,
        TO_last_name: false,
        TO_address2: false,
        L: false,
        W: false,
        H: false,
        "Item-sku": false,
        "phone num1": false,
        "phone num2": false,
        size: false,
        "order num (Client)": false,
        all_bills: false,
        "30D-bill": false,
        "30-60D-bill": false,
        "60-90D-bill": false,
        last_bill_date: false,
        has_bill: false,
        is_enabled: false
      },
      showColumnFilters: true,
      density: "compact",
      pagination: {pageSize: 100, pageIndex: 0},
      defaultColumn: {
        minSize: 20, //allow columns to get smaller than default
        maxSize: 9001, //allow columns to get larger than default
        size: 260 //make columns wider by default
      },
      sorting: [
        {
          id: "date", //sort by age by default on page load
          desc: true
        },
        {
          id: "DATE", //sort by age by default on page load
          desc: true
        },
        {
          id: "last_sign_in", //sort by age by default on page load
          desc: true
        }
      ]
    },
    renderDetailPanel: ({row}) =>
      row.original?.TR_Urgency?.length &&
      row.original?.TR_Urgency?.map((order, index) => (
        <div key={index}>
          <Row
            style={{
              // alignItems: "center",
              display: "flex",
              justifyContent: "start",
              // justifyContent: "space-around",
              // left: "30px",
              // maxWidth: "1000px",
              // position: "sticky",
              backgroundColor: "lightblue",
              width: "100%",
              alignItems: "start"
            }}
          >
            <Col>
              <Typography variant="h6">{order["Tracking NO"]}</Typography>
            </Col>
            <Col style={{width: "30%"}}>
              <Typography style={{textAlign: "start"}} variant="h6">
                {order["carrier_status"]}
              </Typography>
            </Col>
            <Col style={{width: "15%"}}>
              {order["ALL HISTORY Tracing"].events?.map((event, eventIndex) => {
                const carrierStatus = event?.carrier_status_description || ""

                // Use 'map' instead of 'forEach' to render JSX elements
                return keywords.map((keyword, keywordIndex) => {
                  return carrierStatus
                    .toLowerCase()
                    .includes(keyword.toLowerCase()) ? (
                    <li
                      key={`${eventIndex}-${keywordIndex}`}
                      style={{textAlign: "start"}}
                      variant="h6"
                    >
                      {keyword} : {event.occurred_at} | {carrierStatus}
                    </li>
                  ) : null // Return null instead of empty string for no rendering
                })
              })}
            </Col>
          </Row>
          <hr style={{border: "1px dashed white", width: "100%"}} />
        </div>
      )),
    // export or other custom actions
    renderTopToolbarCustomActions: ({table}) => (
      <Box
        sx={{
          // border: "1px green solid",
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          padding: "8px",
          width: "100%",
          flexWrap: "wrap"
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        {/* <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button> */}
        {/* <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button> */}
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected
        </Button>
        <Button
          disabled={
            sendToSheetLoading
            // !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportToSheet(table.getPrePaginationRowModel().rows)
          }
          startIcon={<GridOnIcon />}
        >
          Send To GS
        </Button>

        <Button
          disabled={
            (!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()) ||
            trackLoading
          }
          //only export selected rows
          onClick={() =>
            handleTrack(
              table.getPrePaginationRowModel().rows,
              table.getSelectedRowModel().rows
            )
          }
          startIcon={<RadarIcon />}
        >
          Track
        </Button>
      </Box>
    )
  })

  return <MaterialReactTable table={table} />
}

const Dashboaard = () => {
  const dispatch = useDispatch()

  const [selectedParaValue, setSelectedParaValue] = useState("")
  const [textareaValue, setTextareaValue] = useState("")
  const [selectedCustomReport, setSelectedCustomReport] = useState({})

  const [selectedReport, setSelectedReport] = useState("")
  const [pageReports, setPageReports] = useState([])
  const [selectedPage, setSelectedPage] = useState("")

  const SLReportsList = useSelector((state) => state.SLReportsList)
  const {reportsList, loading} = SLReportsList

  const SLReport = useSelector((state) => state.SLReport)
  const {SLReportsLists, loading: SLReportsListsLoading} = SLReport

  const column = useSelector((state) => state.column)
  const {definedColumns} = column
  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    dispatch(getReportsList({}))
  }, [])

  // const handleRefreshIconClick = (report) => {
  //   setSelectedReport(report.name)
  //   dispatch(
  //     getSLReport(
  //       {name: report.name, url: report.uri, newVersion: true},
  //       SLReportsLists || {}
  //     )
  //   )
  // }

  // async function handleDownloadClick(url) {
  //   console.log(url)
  //   try {
  //     let jsonData
  //     // Step 1: Check if the URL contains a .gz file extension
  //     if (url.split("?")[0].endsWith(".gz")) {
  //       // Step 2: Download the .gz file
  //       const response = await axios({
  //         url,
  //         method: "GET",
  //         responseType: "arraybuffer" // Important to get the file as a buffer
  //       })

  //       const gzFileBuffer = response.data

  //       // Step 3: Decompress the .gz file using pako
  //       const decompressedData = pako.ungzip(gzFileBuffer, {to: "string"})

  //       // Step 4: Parse the JSON data
  //       jsonData = JSON.parse(decompressedData)
  //     } else {
  //       const response = await axios({
  //         url,
  //         method: "GET"
  //       })

  //       const data = response.data

  //       // Step 4: Parse the JSON data
  //       jsonData = JSON.parse(data)
  //     }

  //     console.log(jsonData[0])
  //   } catch (error) {
  //     window.alert(`Failed to fetch or parse the .gz file: ${error.message}`)
  //   }

  //   // dispatch(
  //   //   getSLReport(
  //   //     {name: reportName, newVersion: false},
  //   //     SLReportsLists || {},
  //   //     false
  //   //   )
  //   // )
  // }

  const handleCardClick = (report) => {
    setSelectedReport(report.name)
    dispatch(
      getSLReport(
        {name: report.name, url: report.uri, newVersion: false},
        SLReportsLists || {}
      )
    )
  }

  const handleCustomFilter = () => {
    const newValue = textareaValue.replaceAll(/[\s\n]+/g, ",").split(",")
    const customFilter = {parameter: selectedParaValue, list: newValue}
    dispatch(
      getSLReport(
        {
          name: pageReports[selectedCustomReport].name,
          url: pageReports[selectedCustomReport].uri,
          newVersion: true,
          customFilter: customFilter
        },
        SLReportsLists || {}
      )
    )
    setSelectedReport(pageReports[selectedCustomReport].name)
  }

  const pageTypeHandler = (groupType) => {
    setPageReports(groupType.records)
    setSelectedReport("")
    setSelectedPage(groupType._id)
    switch (`${groupType._id}`) {
      case "Billing Records":
        dispatch({
          type: SET_COLUMNS,
          payload: BillsColumns
        })
        break
      case "Daily Analyzer":
        dispatch({
          type: SET_COLUMNS,
          payload: DailyAnalyzerColumns
        })
        break
      case "Users":
        dispatch({
          type: SET_COLUMNS,
          payload: UsersColumns
        })
        break
      case "Labels":
        dispatch({
          type: SET_COLUMNS,
          payload: LabelsColumns
        })
        break

      default:
        break
    }
  }

  // type: "label"
  // uri: "https://firebasestorage.googleapis.com/v0/b/storage-buffer.appspot.com/o/files%2FUsers_metadata.json?alt=media&token=2fb81d71-dfe3-4f5a-9c13-fea6c6a43e41"
  // _id: "65ca7c018b73d803547e3177"
  return loading ? (
    <Loader />
  ) : (
    <div id="dashboard">
      <div className="pages">
        Pages:{" "}
        {reportsList?.map((record, index) => (
          <Button
            style={{
              margin: "3px",
              padding: "5px",
              border: "1px solid blue",
              background: selectedPage === record._id ? "blue" : "",
              color: selectedPage === record._id ? "white" : ""
            }}
            key={index}
            onClick={() => pageTypeHandler(record)}
          >
            <h6>{record._id}</h6>
          </Button>
        ))}
      </div>
      <div className="reports">
        <div key="reports-A" className="fixed-card-filter">
          {pageReports?.map((report, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                border:
                  selectedReport === report?.name
                    ? "3px solid var(--Primary)"
                    : "1px solid var(--Secondary)",
                borderRadius: "5px",
                margin: "3px",
                padding: "3px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Card
                  className="reports-list-cards"
                  key={index}
                  style={
                    {
                      // background: selectedReport === report?.name ? "cyan" : ""
                    }
                  }
                  onClick={() => handleCardClick(report)}
                >
                  <h6>Report: {report?.name?.replaceAll("_", " ")}</h6>
                  <p>
                    Last Request:{" "}
                    {report?.lastRequestTime
                      ? UTCToLocal(report?.lastRequestTime)
                      : "---"}
                    {/* Add the refresh icon here */}
                  </p>
                </Card>
                {/* <Link href={report.uri}>Download</Link> */}
                {/* <Button
                  style={{fontSize: "12px", padding: 0}}
                  onClick={() => handleDownloadClick(report.uri)}
                >
                  Download
                </Button> */}
              </div>
              {/* <FaSync
                style={{marginLeft: "5px", cursor: "pointer"}}
                onClick={() => handleRefreshIconClick(report)}
              /> */}
            </div>
          ))}
        </div>
        {selectedReport === "billing_hist" && (
          <div
            style={{
              display: "flex",
              maxHeight: "75px"
            }}
          >
            <Box
              style={{
                backgroundColor: "lightcyan",
                padding: "10px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px dashed grey"
              }}
            >
              last Month: {SLReportsLists?.lastMonth || ""}
            </Box>
            <Box
              style={{
                backgroundColor: "lightcyan",
                padding: "10px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px dashed grey"
              }}
            >
              current Month: {SLReportsLists?.currentMonth || ""}
            </Box>
          </div>
        )}
        {pageReports.length > 0 && (
          <div key="reports-B" className="custom-filter">
            <div>
              <h4>
                {" "}
                From Report:{" "}
                <select
                  value={selectedCustomReport}
                  onChange={(e) => setSelectedCustomReport(e.target.value)}
                >
                  <option value="">Select an option</option>
                  {pageReports?.map((report, index) => (
                    <option key={index} value={index}>
                      {report.name}
                    </option>
                  ))}
                </select>
              </h4>
              <h4>
                Query by:{" "}
                <span>
                  <select
                    value={selectedParaValue}
                    onChange={(e) => setSelectedParaValue(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Tracking NO">Tracking Number</option>
                    <option value="ORDER #">Order #</option>
                    <option value="EMAIL">E-mail</option>
                  </select>
                </span>{" "}
              </h4>
              <textarea
                rows="3"
                cols="50"
                value={textareaValue}
                onChange={(e) => setTextareaValue(e.target.value)}
                placeholder="Enter your filtering paramter value"
              ></textarea>
            </div>
            <Button
              onClick={() => handleCustomFilter()}
              disabled={
                textareaValue === "" ||
                selectedParaValue === "" ||
                selectedCustomReport === ""
              }
              style={{
                width: "100%",
                border: "1px solid grey",
                borderRadius: "5px",
                padding: "5px",
                margin: "5px"
              }}
            >
              Done
            </Button>
          </div>
        )}
      </div>

      {SLReportsListsLoading && <Loader />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {definedColumns?.length > 0 && (
          <Example
            selectedReport={selectedReport}
            records={SLReportsLists ? SLReportsLists[selectedReport] || [] : []}
            SLReportsLists={SLReportsLists}
          />
        )}
      </LocalizationProvider>
    </div>
  )
}

export default Dashboaard
